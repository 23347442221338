import {AuthConstants as Constants} from './../Types'
import {tokenName, storageKey} from '../../config.json'
const initialState = {
    user: null,
    token: null,
};



export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        
        case Constants.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token
            }   
            
        case Constants.AUTH:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token
            } 
            
        case Constants.UPDATE:
            return {
                ...state,
                user: action.payload.user
            } 

        case Constants.FAILED:
            return {
                ...state,
                user: null
            } 
        
        case Constants.LOGOUT:
            localStorage.removeItem(tokenName);
            localStorage.removeItem(storageKey);
            return {
                user: null
            }
            
        default:
            return state
            

    }
}