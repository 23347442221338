import React, { createContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AuthActions } from './../Redux/Actions';
import {tokenName} from '../config.json'
import { authApi } from '../Api';

const initialState = {
    user: null,   
    loggedIn: false,
    loading: true,
    token: localStorage.getItem(tokenName)
};

const AuthContext = createContext({
    ...initialState,
    init: () => Promise.resolve(),
    login: () => Promise.resolve(),     
    logout: () => Promise.resolve(),
});


export const AuthProvider = (props) => {
    const auth = useSelector(state => state.auth);
    const [state, setState] = useState({...initialState, ...auth})
    const dispatch = useDispatch()

    

    useEffect(() => {
      init()
      // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [])

    useEffect(() => {
        setState({
            ...state,
            ...auth
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    const init = () => {      
        setTimeout(function() { loader() }, 1500);
    }

    const login = (form_data) => {
        return new Promise((resolve, reject) => {
            dispatch(AuthActions.Login(form_data))
            .then((response) => {
                resolve(response) 
            })
            .catch((error) => reject(error) )
        })

    }

    const logout = () => {
        return new Promise((resolve, reject) => {
        dispatch(AuthActions.Logout())
            .then(() => {
                setState({...state, user:null, token: null})                
                resolve()
            })
            .catch((e) => {
                reject()
            })
        })

    }

    const authCheck = () => {
        return new Promise((resolve, reject) => {
            authApi._auth_check()
                .then((res) => {
                    setState({...state, loading: false})
                    resolve()
                })
                .catch((e) => {
                    setState({...state, loading: false})
                    // logout()
                    reject()
                })
        })
    }


    /* Check initial authentication here */
    const loader = async () => {
        await authCheck()
    }



    
    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout
            }}>
            {props.children}
            
        </AuthContext.Provider>
    );
}

export  {AuthContext}