import React, {Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {Splash} from '../Kit'
import {Fade} from '@chakra-ui/react'
import { useAuth } from '../Hooks'
import { isEmpty } from '../Helpers'
import NotFound from '../Pages/Errors/404'
const Login = lazy(() => import('./../Pages/Auth'))
const Dashboard = lazy(() => import('./../Pages/Dashboard'))



const  Routes = () => {    
    
    const auth = useAuth();

    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }

    if(isEmpty(auth.user) || !auth.token) 
    {
        return (
            <Fade in={true}>
                <Suspense fallback={<Splash />}>
                    <Router>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route path="*"  component={NotFound} /> 
                        </Switch>
                    </Router>
                </Suspense>
            </Fade>
        )
    }

    return (        
        <Fade in={true}>
            <Suspense fallback={<Splash />}>
                <Router>
                    <Switch>
                        <Route path="/" component={Dashboard} />                        
                    </Switch>
                </Router>
            </Suspense>
        </Fade>        
    )
}

export default Routes;