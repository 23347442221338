import { CategoryConstants as Constants } from '../Types'
const initialState = {
    categories: []
};

export function CategoryReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                categories: action.payload.categories,
            };
       
        case Constants.ADD:
            return {
                ...state,               
                categories: [...state.categories, action.payload.category]
            };

        case Constants.UPDATE:
            let index = state.categories.findIndex((item) => parseInt(item.id) === parseInt(action.payload.category.id));
            let itemsArray = [...state.categories];
            if(index > -1)
                itemsArray[index] = action.payload.category
            return {
                ...state,               
                categories: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                categories: state.categories.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}