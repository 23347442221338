import { DealConstants as Constants } from '../Types'
const initialState = {
    deals: []
};

export function DealReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                deals: action.payload.deals,
            };
       
        case Constants.ADD:
            return {
                ...state,               
                deals: [...state.deals, action.payload.deal]
            };

        case Constants.UPDATE:
            let index = state.deals.findIndex((item) => parseInt(item.id) === parseInt(action.payload.deal.id));
            let itemsArray = [...state.deals];
            if(index > -1)
                itemsArray[index] = action.payload.deal
            return {
                ...state,               
                deals: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                deals: state.deals.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}