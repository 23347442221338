import { apiUrl, tokenName, storageKey, apiMaxTime } from '../config.json' 
import {handleResponse, requestTimeout} from './../Helpers'

export const authApi = {
    _login,
    _logout,
    _signup,  
    _auth_check,  
};



async function _login(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'login', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                        localStorage.setItem(tokenName, user.token);
                        return {user: user.user, token: user.token}
                    }                    
                )
}

async function _signup(form_data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'signup', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                        localStorage.setItem(tokenName, user.token);
                        return {user: user.user, token: user.token}
                    }                    
                )                    
}

async function _auth_check() {
    let token = localStorage.getItem(tokenName)
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    };

    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin-auth-check', requestOptions))
                .then(handleResponse)
                .then(async (user) => {
                    if (user.token) {                
                        localStorage.setItem(tokenName, user.token);
                    }
                    return user;
                });
}


async function _logout(){
    localStorage.removeItem(tokenName);
    localStorage.removeItem(storageKey);    
    return true;
}
