import { CustomerConstants as Constants } from '../Types'
const initialState = {
    customers: []
};

export function CustomerReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                customers: action.payload.customers,
            };
       
        case Constants.ADD:
            return {
                ...state,               
                customers: [...state.customers, action.payload.customer]
            };

        case Constants.UPDATE:
            let index = state.customers.findIndex((item) => parseInt(item.id) === parseInt(action.payload.customer.id));
            let itemsArray = [...state.customers];
            if(index > -1)
                itemsArray[index] = action.payload.customer
            return {
                ...state,               
                customers: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                customers: state.customers.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}