import { OrderConstants as Constants } from '../Types'
const initialState = {
    orders: [],
    stats: {}
};

export function OrderReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                orders: action.payload.orders,
            };
       
        case Constants.ADD:
            return {
                ...state,               
                orders: [...state.orders, action.payload.order]
            };

        case Constants.UPDATE:
            let index = state.orders.findIndex((item) => parseInt(item.id) === parseInt(action.payload.order.id));
            let itemsArray = [...state.orders];
            if(index > -1)
                itemsArray[index] = action.payload.order
            return {
                ...state,               
                orders: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                orders: state.orders.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.STATS:
            return {
                ...state,               
                stats: action.payload.stats,
            };
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}