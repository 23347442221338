import { ItemConstants as Constants } from '../Types'
const initialState = {
    items: []
};

export function ItemReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                items: action.payload.items,
            };

        case Constants.ADD:
            return {
                ...state,               
                items: [...state.items, action.payload.item]
            };

        case Constants.UPDATE:
            let index = state.items.findIndex((item) => parseInt(item.id) === parseInt(action.payload.item.id));
            let itemsArray = [...state.items];
            if(index > -1)
                itemsArray[index] = action.payload.item
            return {
                ...state,               
                items: itemsArray
            };
       
              
        case Constants.REMOVE:
            return {
                ...state,               
                items: state.items.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.FAILED:
            return{
                ...state,
            }

        default:
            return state
    }
}