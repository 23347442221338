export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    UPDATE: 'UPDATE_PROFILE',
}

export const ServicesConstants = {
    GET: 'GET_SERVICES',
    FAILED: 'SERVICES_FAILED',    
    ADD: 'ADD_SERVICE',
    REMOVE: 'REMOVE_SERVICE',
    UPDATE: 'UPDATED_SERVICE'
};

export const CategoryConstants = {
    GET: 'GET_CATEGORIES',
    FAILED: 'CATEGORIES_FAILED',    
    ADD: 'ADD_CATEGORY',
    REMOVE: 'REMOVE_CATEGORY',
    UPDATE: 'UPDATED_CATEGORY'
};

export const ItemConstants = {
    GET: 'GET_ITEMS',
    ADD: 'ADD_ITEM',
    UPDATE: 'UPDATE_ITEM',
    REMOVE: 'REMOVE_ITEM',    
    FAILED: 'ITEMS_FAILED',    
};

export const CustomerConstants = {
    GET: 'GET_CUSTOMERS',
    FAILED: 'CUSTOMERS_FAILED',    
    ADD: 'ADD_CUSTOMER',
    REMOVE: 'REMOVE_CUSTOMER',
    UPDATE: 'UPDATED_CUSTOMER'
};

export const OrderConstants = {
    GET: 'GET_ORDERS',
    FAILED: 'ORDERS_FAILED',    
    ADD: 'ADD_ORDER',
    REMOVE: 'REMOVE_ORDER',
    UPDATE: 'UPDATED_ORDER',
    STATS: 'GET_STATS'
};

export const DealConstants = {
    GET: 'GET_DEALS',
    FAILED: 'DEALS_FAILED',    
    ADD: 'ADD_DEAL',
    REMOVE: 'REMOVE_DEAL',
    UPDATE: 'UPDATED_DEAL'
};

export const CouponConstants = {
    GET: 'GET_COUPONS',
    FAILED: 'COUPONS_FAILED',    
    ADD: 'ADD_COUPONS',
    REMOVE: 'REMOVE_COUPON',
    UPDATE: 'UPDATED_COUPON'
};