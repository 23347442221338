import { ServicesConstants as Constants } from '../Types'
const initialState = {
    services: []
};

export function ServiceReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                services: action.payload.services,
            };

        case Constants.ADD:
            return {
                ...state,               
                services: [...state.services, action.payload.service]
            };

        case Constants.UPDATE:
            let index = state.services.findIndex((item) => parseInt(item.id) === parseInt(action.payload.service.id));
            let itemsArray = [...state.services];
            if(index > -1)
                itemsArray[index] = action.payload.service
            return {
                ...state,               
                services: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                services: state.services.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
              
            
        case Constants.FAILED:
            return{
                ...state,
            }

        default:
            return state
    }
}