import { AuthConstants as Constants } from './../Types'
import { authApi as reqApi } from './../../Api'

export const AuthActions = {
    Login,
    Logout
};


function Login(form_data) {

    return dispatch => {
        return new Promise((resolve, reject) => {
            reqApi._login(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.LOGIN, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function Logout() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            reqApi._logout()
                .then(
                    payload => {
                        dispatch(success());
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
            dispatch(success())
            resolve()
        });
    function success() { return { type: Constants.LOGOUT } }
    function failure() { return { type: Constants.FAILED } }
    }
}



