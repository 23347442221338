import React from "react";
import {Box, Spinner} from '@chakra-ui/react'

export const Loader = (props) => {
    return(
        <Box 
            w="100%"
            d="flex" 
            minH={'100vh'} 
            alignItems="center" 
            justifyContent="center" 
        >
            <Spinner size="lg" />
        </Box>
    )
}
