import { apiUrl, tokenName, apiMaxTime } from '../config.json' 
import {handleResponse, requestTimeout} from './../Helpers'

export const itemsApi = {
    _get,
    _add,
    _update,
    _delete,
};
async function _get() {
    let app_token = localStorage.getItem(tokenName);
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin/items', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}


async function _add(data) {
    let app_token = localStorage.getItem(tokenName);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin/items', requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _update(data, id) {
    let app_token = localStorage.getItem(tokenName);
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin/items/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

async function _delete(id) {
    let app_token = localStorage.getItem(tokenName);
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer '+app_token
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'admin/items/'+id, requestOptions))
                .then(handleResponse)
                .then((data) => {                
                    return data;
                });
}

