import { combineReducers } from 'redux'
import { AuthReducer } from './Auth.reducer'
import { CategoryReducer } from './Category.reducer';
import { CouponReducer } from './Coupon.reducer';
import { CustomerReducer } from './Customer.reducer';
import { DealReducer } from './Deal.reducer';
import { ItemReducer } from './Item.reducer';
import { OrderReducer } from './Order.reducer';
import { ServiceReducer } from './Service.reducer';

export default combineReducers({
    auth: AuthReducer,    
    services: ServiceReducer,
    categories: CategoryReducer,
    items: ItemReducer,
    customers: CustomerReducer,
    orders: OrderReducer,
    deals: DealReducer,
    coupons: CouponReducer
});