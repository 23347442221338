export const icons = [
    {name: 'Skirt', ico: 'skirt'},
    {name: 'Shorts', ico: 'shorts'},
    {name: 'Shorts 1', ico: 'shorts1'},
    {name: 'Shorts 3', ico: 'shorts3'},
    {name: 'Shorts 4', ico: 'shorts4'},
    {name: 'Shorts 5', ico: 'shorts11'},
    {name: 'Shorts 21', ico: 'shorts21'},
    {name: 'Dungarees', ico: 'dungarees'},
    {name: 'Top', ico: 'top'},
    {name: 'Top 1', ico: 'top1'},
    {name: 'Top 2', ico: 'top2'},
    {name: 'Top 11', ico: 'top11'},
    {name: 'Top 3', ico: 'top3'},
    {name: 'Top 4', ico: 'top4'},
    {name: 'Top 5', ico: 'top5'},
    {name: 'Top 6', ico: 'top6'},
    {name: 'Top 7', ico: 'top7'},
    {name: 'Skit 1', ico: 'skirt1'},
    {name: 'Skit 2', ico: 'skirt2'},
    {name: 'Skit 3', ico: 'skirt3'},
    {name: 'Skit 4', ico: 'skirt4'},
    {name: 'Skit 5', ico: 'skirt11'},
    {name: 'Skit 6', ico: 'skirt21'},
    {name: 'Skit 7', ico: 'skirt31'},
    {name: 'Skit 8', ico: 'skirt41'},
    {name: 'Skit 9', ico: 'skirt5'},
    {name: 'Outfit', ico: 'Outfit'},
    {name: 'Poncho', ico: 'poncho'},
    {name: 'Jeans', ico: 'jeans'},
    {name: 'Jeans 1', ico: 'jeans1'},
    {name: 'Jeans 2', ico: 'jeans2'},
    {name: 'Jeans 11', ico: 'jeans11'},
    {name: 'Suit', ico: 'suit'},
    {name: 'Trousers', ico: 'trousers'},
    {name: 'Trousers 1', ico: 'trousers1'},
    {name: 'Trousers 2', ico: 'trousers2'},
    {name: 'Trousers 3', ico: 'trousers3'},
    {name: 'Shirt', ico: 'shirt'},
    {name: 'Shirt 1', ico: 'shirt1'},
    {name: 'Shirt 2', ico: 'shirt2'},
    {name: 'Shirt 3', ico: 'shirt3'},
    {name: 'Shirt 4', ico: 'shirt4'},
    {name: 'Shirt 5', ico: 'shirt5'},
    {name: 'Shirt 6', ico: 'shirt6'},
    {name: 'Shirt 7', ico: 'shirt7'},
    {name: 'Shirt 8', ico: 'shirt8'},
    {name: 'Shirt 9', ico: 'shirt9'},
    {name: 'Shirt 10', ico: 'shirt10'},
    {name: 'Shirt 11', ico: 'shirt11'},
    {name: 'Shirt 21', ico: 'shirt21'},
    {name: 'Shirt 31', ico: 'shirt31'},
    {name: 'Shirt 41', ico: 'shirt41'},
    {name: 'Shirt 51', ico: 'shirt51'},
    {name: 'Shirt 61', ico: 'shirt61'},
    {name: 'Shirt 71', ico: 'shirt71'},
    {name: 'Shirt 81', ico: 'shirt81'},
    {name: 'Shirt 91', ico: 'shirt91'},
    {name: 'Shirt 12', ico: 'shirt12'},
    {name: 'Polo Shirt', ico: 'polo-shirt'},
    {name: 'Polo Shirt 1', ico: 'polo-shirt1'},
    {name: 'Jumper', ico: 'jumper'},
    {name: 'Jumper 1', ico: 'jumper1'},
    {name: 'Jumper 2', ico: 'jumper2'},
    {name: 'Jumper 3', ico: 'jumper3'},
    {name: 'Hoodie', ico: 'hoodie'},
    {name: 'Hoodie 1', ico: 'hoodie1'},
    {name: 'Kimono', ico: 'kimono'},
    {name: 'Swimsuit', ico: 'swimsuit'},
    {name: 'Swimsuit Alt', ico: 'swimsuit1'},    
    {name: 'Socks', ico: 'socks'},
    {name: 'Socks 1', ico: 'socks1'},
    {name: 'Coat', ico: 'coat'},
    {name: 'Coat 1', ico: 'coat1'},
    {name: 'Coat 2', ico: 'coat2'},
    {name: 'Coat 3', ico: 'coat3'},
    {name: 'Coat 4', ico: 'coat4'},
    {name: 'Coat 5', ico: 'coat5'},
    {name: 'Coat 6', ico: 'coat6'},
    {name: 'Coat 31', ico: 'coat31'},
    {name: 'Jacket', ico: 'jacket'},
    {name: 'Jacket 1', ico: 'jacket1'},
    {name: 'Jacket 2', ico: 'jacket2'},
    {name: 'Jacket 3', ico: 'jacket3'},
    {name: 'Jacket 4', ico: 'jacket4'},
    {name: 'Dress', ico: 'dress'},
    {name: 'Dress 1', ico: 'dress1'},
    {name: 'Dress 2', ico: 'dress2'},
    {name: 'Dress 3', ico: 'dress3'},
    {name: 'Dress 4', ico: 'dress4'},
    {name: 'Dress 5', ico: 'dress5'},
    {name: 'Dress 11', ico: 'dress11'},
    {name: 'Dress 21', ico: 'dress21'},
    {name: 'Dress 31', ico: 'dress31'},
    {name: 'Jumpsuit', ico: 'jumpsuit'},
    {name: 'Vest', ico: 'vest'},
    {name: 'Vest 1', ico: 'vest1'},
    {name: 'Vest 2', ico: 'vest2'},
    {name: 'Bodysuit', ico: 'bodysuit'},
    {name: 'Waist Coat', ico: 'waistcoat'},
    {name: 'Dryclean', ico: 'dryclean'},
    {name: 'Iron', ico: 'irn'},
    {name: 'Wash', ico: 'wash'},
    {name: 'Wash & Iron', ico: 'wash2'}
]