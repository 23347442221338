import React, {useState, useEffect} from 'react'
import {
    Box, Input, Tooltip, InputGroup, InputLeftElement
} from '@chakra-ui/react'
import { Icon, Modal } from '.'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';


const ChakraDatePicker = ({icon, minTime, maxTime, timeIntervals, minDate, onChange, maxDate, format="DD/MM/YYYY",  selected, showTime, tooltip }) => {

    const [isOpen, setIsOpen] = useState(false)
   
    const [value, setValue] = useState(selected ? selected : (minDate || new Date()))

    useEffect(() => {
      if(selected)
        setValue(selected)
    }, [selected])

    useEffect(() => {
        if(minDate)
        setValue(minDate)
    }, [minDate])

    function handleInputChange(value)
    {
        setValue(value)
        onChange(value)
    }


    return(
            <>
            <Box onClick={() => setIsOpen(true)}>
                <Tooltip label={tooltip || 'Select Date'} placement="right"  hasArrow bg="gray.500">
                    <InputGroup>
                        {
                            (icon) &&
                            <InputLeftElement 
                                pointerEvents="none"
                                children={<Icon name={icon}  />}
                            />                                
                        } 
                        <Input onClick={() => setIsOpen(true)} placeholder="Select Date" readOnly value={moment(value).format(format)} />                
                    </InputGroup>
                </Tooltip>
            </Box>
            <Modal
                open={isOpen}
                size="md"
                p={0}
                transition="slideInBottom"
                title={tooltip || "Select Date..."}
                scrollType="inline"
                bg="white"
                onClose={() => setIsOpen(false)}
                >
                    <DatePicker 
                        inline 
                        minDate={minDate} 
                        maxDate={maxDate}
                        minTime={minTime}
                        maxTime={maxTime}
                        showTimeSelect={showTime}
                        selected={value} 
                        timeIntervals={timeIntervals || 15}
                        dateFormat={format}
                        timeFormat="HH:mm"
                        // onSelect={(date) => handleInputChange(date)} 
                        onChange={(date) => handleInputChange(date)} 
                    />
            </Modal>
            
        
        </>
    )
}

export {ChakraDatePicker}