import { CouponConstants as Constants } from '../Types'
const initialState = {
    coupons: []
};

export function CouponReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,               
                coupons: action.payload.coupons,
            };
       
        case Constants.ADD:
            return {
                ...state,               
                coupons: [...state.coupons, action.payload.coupon]
            };

        case Constants.UPDATE:
            let index = state.coupons.findIndex((item) => parseInt(item.id) === parseInt(action.payload.coupon.id));
            let itemsArray = [...state.coupons];
            if(index > -1)
                itemsArray[index] = action.payload.coupon
            return {
                ...state,               
                coupons: itemsArray
            };
        
                
        case Constants.REMOVE:
            return {
                ...state,               
                coupons: state.coupons.filter((item) => parseInt(item.id) !== parseInt(action.payload.id))
            };
            
        case Constants.FAILED:
            return{
                ...state
            }

        default:
            return state
    }
}