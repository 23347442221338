import { extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { button } from "./button"
import { input } from "./input"

export const theme = extendTheme({
    colors: colors,
    components:{
        Button: button,
        Input: input,
    }
})